/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';

export type IllustrationSizeVariants =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';
export type IllustrationProps = {
  width?: number | string;
  height?: number | string;
  size?: IllustrationSizeVariants;
};
const IllustrationSpotCvDownload = ({
  size = 'medium',
  width,
  height,
  ...props
}: IllustrationProps) => {
  let presetWidth: string | undefined;
  let presetHeight: string | undefined;
  const presets = {
    small: '48px',
    medium: '68px',
    large: '92px',
    xlarge: '122px',
    xxlarge: '156px',
    xxxlarge: '194px',
    xxxxlarge: '236px',
  };
  if (size) {
    presetWidth = presets[size];
    presetHeight = presets[size];
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 68 68"
      data-xds="IllustrationSpotCvDownload"
      height={height || presetHeight}
      role="presentation"
      width={width || presetWidth}
      {...props}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M51.795 2.5v37.438a13 13 0 0 0-4.552-.814c-2.313 0-4.486.6-6.372 1.654A12.94 12.94 0 0 1 46.5 39.5c7.18 0 13 5.82 13 13s-5.82 13-13 13c-5.517 0-10.231-3.436-12.119-8.285L8.5 56.98V2.5z"
        clipRule="evenodd"
      />
      <path
        fill="var(--xdlColorIllustrationOutlinePrimary)"
        fillRule="evenodd"
        d="M52.794 1.5v6.332l3.557.001.001 34.72A13.96 13.96 0 0 1 60.5 52.5c0 7.732-6.268 14-14 14a13.97 13.97 0 0 1-10.908-5.224H14.459l-.001-3.242-6.958-.063V1.501zm-6.294 39c-6.627 0-12 5.372-12 12 0 6.627 5.373 12 12 12s12-5.373 12-12c0-6.628-5.373-12-12-12m4.294-37H9.5v52.489l23.495.212A14 14 0 0 1 32.5 52.5a13.97 13.97 0 0 1 5.012-10.735H15.6v-2l25.073.001A13.95 13.95 0 0 1 46.5 38.5c1.498 0 2.941.235 4.295.67zM29.07 46.235v2H15.84v-2zm15.624-12.6v2H15.6v-2zm0-6.131v2H15.6v-2zm0-6.131v2H15.6v-2zm0-6.13v2H28.531v-2zm0-6.132v2H28.531v-2z"
        clipRule="evenodd"
      />
      <path
        fill="#B7E854"
        fillRule="evenodd"
        d="M16.406 18.348a4.383 4.383 0 1 1 8.766 0zm7.095-7.875a2.674 2.674 0 1 0-5.348-.001 2.674 2.674 0 0 0 5.348 0"
        clipRule="evenodd"
      />
      <path
        fill="#27DBA8"
        d="m46.536 59.5 5.583-7.163h-3.62l.001-6.464h-4v6.465h-3.547z"
      />
    </svg>
  );
};
export default IllustrationSpotCvDownload;
