import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { articles } from '@xing-com/crate-lebenslauf-articles';
import { ArticleCard, MetaData } from '@xing-com/crate-lebenslauf-components';
import { templates } from '@xing-com/crate-lebenslauf-constants';
import {
  useBasePath,
  useIsCrateApp,
  useVitaTracking,
} from '@xing-com/crate-lebenslauf-hooks';
import {
  IllustrationSpotCvDownload,
  IllustrationSpotDragnDrop,
  IllustrationSpotSkills,
} from '@xing-com/illustrations';
import IntersectionObserver from '@xing-com/intersection-observer';
import { scale360, spaceL } from '@xing-com/tokens';
import { Hero, Headline, BodyCopy } from '@xing-com/typography';

import Animation1 from '../assets/A1.svg';
import Animation2 from '../assets/A2.svg';
import Animation3 from '../assets/A3.svg';
import Animation4 from '../assets/A4.svg';
import appStoreBadgeDe from '../assets/app-store-badge-de.svg';
import appStoreBadgeEn from '../assets/app-store-badge-en.svg';
import googlePlayBadgeDe from '../assets/google-play-badge-de.png';
import googlePlayBadgeEn from '../assets/google-play-badge-en.png';
import templatesPreviewImage1x from '../assets/templates-preview-1x.png';
import templatesPreviewImage2x from '../assets/templates-preview-2x.png';
import { Turntable } from '../turntable';
import type { BadgesMap, IntersectionObserverEntry } from '../types';

import { CoverLetterSection } from './cover-letter-section';
import {
  StyledSection,
  StyledIllustrationSection,
  StyledCTA,
  StyledHeadline,
  StyledSteps,
  StyledStep,
  StyledIconCopy,
  StyledIllustration,
  StyledIllustrationLeft,
  StyledStepsCopy,
  StyledAppleBadge,
  StyledGoogleBadge,
  StyledAppsHeadline,
  StyledAnimationWrapper,
  StyledAnimation,
  StyledAnimationStep,
  StyledArticles,
  StyledCarousel,
  FullWidthWrapper,
  Wrapper,
  TemplatesPreview,
} from './cv-startpage.styled';

const animations = [Animation1, Animation2, Animation3, Animation4];
const badgesMap: BadgesMap = {
  de: {
    apple: appStoreBadgeDe,
    google: googlePlayBadgeDe,
  },
  en: {
    apple: appStoreBadgeEn,
    google: googlePlayBadgeEn,
  },
};

const lebMetada = {
  title: 'Lebenslauf Online Editor | Mit vielen Vorlagen & als PDF | XING',
  description:
    'Schreibe ganz einfach deinen perfekten Lebenslauf im Online Editor ✓ Zahlreiche Vorlagen & Muster ✓ PDF Download ✓ Sichere Daten ➤ Ein XING Service',
};
const xingMetada = {
  title: 'Lebenslauf Online erstellen - Wähle aus vielen Vorlagen aus | XING',
  description:
    'Erstelle Deinen Lebenslauf in unserem Online Editor ✓ Hier warten zahlreiche Vorlagen & Muster auf Dich und ein PDF Download. Der einfache Weg zu Deinem individuellen Lebenslauf | XING',
};

export const CvStartpage: React.FC = () => {
  const { formatMessage, locale } = useIntl();
  const { trackPageView } = useVitaTracking({ pageName: 'home' });
  const basePath = useBasePath();
  const isCrateApp = useIsCrateApp();
  const [isAnimationVisible, setIsAnimationVisible] = useState(false);
  const [currentAnimationIndex, setCurrentAnimationIndex] = useState(0);
  const currentLanguage = locale === 'de' || locale === 'en' ? locale : 'en';
  const [currentBackgroundColor, setCurrentBackgroundColor] = React.useState(
    templates[2].bgColor
  );
  const handleIntersectionChange = ({
    isIntersecting,
  }: IntersectionObserverEntry): void => {
    setIsAnimationVisible(isIntersecting ?? !isAnimationVisible);
    setCurrentAnimationIndex(0);
  };
  const metadata = isCrateApp ? xingMetada : lebMetada;

  useEffect(() => {
    const interval = setInterval(() => {
      if (isAnimationVisible) {
        setCurrentAnimationIndex((prevAnimationIndex) =>
          prevAnimationIndex === 3 ? 0 : prevAnimationIndex + 1
        );
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [isAnimationVisible]);

  useEffect(() => trackPageView(), [trackPageView]);

  return (
    <>
      <MetaData title={metadata.title} description={metadata.description} />

      <FullWidthWrapper $backgroundColor={currentBackgroundColor}>
        <StyledSection $isHeroSection>
          <Hero size="medium" sizeConfined="large">
            <FormattedMessage
              id={
                isCrateApp
                  ? 'CRATE_LEBENSLAUF_XING_STARTPAGE_TITLE'
                  : 'CRATE_LEBENSLAUF_STARTPAGE_TITLE'
              }
            />
          </Hero>
          <BodyCopy size="xlarge">
            <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_SUBTITLE" />
          </BodyCopy>
          <StyledCTA
            variant="primary"
            size="medium"
            to={`${basePath}/neu?sc_o=lebenslauf_home_create_cv_click&sc_o_PropActionOrigin=lebenslauf_home_body`}
          >
            <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_CTA" />
          </StyledCTA>
          <Turntable
            slides={templates.filter(({ type }) => type === 'cv')}
            setCurrentBackgroundColor={setCurrentBackgroundColor}
          />
          <TemplatesPreview
            src={templatesPreviewImage1x}
            srcSet={`${templatesPreviewImage1x} 1x, ${templatesPreviewImage2x} 2x`}
            loading="lazy"
            decoding="async"
            width="1000px"
            height="564px"
          />
        </StyledSection>
      </FullWidthWrapper>

      <Wrapper>
        <StyledSection>
          <StyledHeadline size="xxxlarge">
            <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_3_STEPS_HEADLINE" />
          </StyledHeadline>
          <BodyCopy size="large">
            <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_3_STEPS_SUBLINE" />
          </BodyCopy>
          <StyledSteps>
            <StyledStep>
              <IllustrationSpotDragnDrop />
              <StyledIconCopy size="medium">
                <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_STEP_1" />
              </StyledIconCopy>
            </StyledStep>
            <StyledStep>
              <IllustrationSpotSkills />
              <StyledIconCopy size="medium">
                <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_STEP_2" />
              </StyledIconCopy>
            </StyledStep>
            <StyledStep>
              <IllustrationSpotCvDownload />
              <StyledIconCopy size="medium">
                <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_STEP_3" />
              </StyledIconCopy>
            </StyledStep>
          </StyledSteps>
        </StyledSection>

        <CoverLetterSection />

        <StyledSection $alignment="left">
          <StyledHeadline size="xxxlarge">
            <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_TOP_ARTICLES_HEADLINE" />
          </StyledHeadline>
          <BodyCopy size="large">
            <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_TOP_ARTICLES_SUBLINE" />
          </BodyCopy>
          <StyledCarousel
            itemMaxWidth={scale360}
            itemMinWidth={scale360}
            itemSpacing={spaceL}
            itemSpacingConfined={spaceL}
            itemSnapPosition="center"
          >
            {articles
              .filter(
                ({ isTopArticle, domain }) =>
                  isTopArticle &&
                  domain === (isCrateApp ? 'xing' : 'lebenslauf')
              )
              .map((article) => (
                <ArticleCard
                  key={article.url}
                  {...{
                    title: article.title,
                    description: article.description,
                    to: article.url,
                    image: article.image?.srcWide ?? '',
                    buttonCopy: formatMessage({
                      id: 'CRATE_LEBENSLAUF_STARTPAGE_ARTICLES_READ_MORE',
                    }),
                  }}
                />
              ))}
          </StyledCarousel>
          <StyledArticles>
            {articles
              .filter(
                ({ isTopArticle, domain }) =>
                  isTopArticle &&
                  domain === (isCrateApp ? 'xing' : 'lebenslauf')
              )
              .map((article) => (
                <ArticleCard
                  key={article.url}
                  {...{
                    title: article.title,
                    description: article.description,
                    to: article.url,
                    image: article.image?.srcWide ?? '',
                    buttonCopy: formatMessage({
                      id: 'CRATE_LEBENSLAUF_STARTPAGE_ARTICLES_READ_MORE',
                    }),
                  }}
                />
              ))}
          </StyledArticles>
        </StyledSection>

        <StyledIllustrationSection>
          <div>
            <StyledHeadline size="xxxlarge">
              <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_YOUR_WISHES_HEADLINE" />
            </StyledHeadline>
            <BodyCopy size="large">
              <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_YOUR_WISHES_SUBLINE" />
            </BodyCopy>
          </div>
          <StyledIllustrationLeft width="466" height="375" aria-hidden="true" />
        </StyledIllustrationSection>

        <StyledIllustrationSection>
          <div>
            <StyledHeadline size="xxxlarge">
              <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_DREAM_JOB_HEADLINE" />
            </StyledHeadline>
            <BodyCopy size="large">
              <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_DREAM_JOB_SUBLINE" />
            </BodyCopy>
          </div>
          <StyledIllustration width="351" height="385" aria-hidden="true" />
        </StyledIllustrationSection>

        <StyledSection $alignment="left">
          <StyledHeadline size="xxxlarge">
            <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_ARTICLES_HEADLINE" />
          </StyledHeadline>
          <BodyCopy size="large">
            <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_ARTICLES_SUBLINE" />
          </BodyCopy>
          <StyledCarousel
            itemMaxWidth={scale360}
            itemMinWidth={scale360}
            itemSpacing={spaceL}
            itemSpacingConfined={spaceL}
            itemSnapPosition="center"
          >
            {articles
              .filter(
                ({ isTopArticle, domain }) =>
                  !isTopArticle &&
                  domain === (isCrateApp ? 'xing' : 'lebenslauf')
              )
              .map((article) => (
                <ArticleCard
                  key={article.url}
                  {...{
                    title: article.title,
                    description: article.description,
                    to: article.url,
                    image: article.image?.srcWide ?? '',
                    buttonCopy: formatMessage({
                      id: 'CRATE_LEBENSLAUF_STARTPAGE_ARTICLES_READ_MORE',
                    }),
                  }}
                />
              ))}
          </StyledCarousel>
          <StyledArticles>
            {articles
              .filter(
                ({ isTopArticle, domain }) =>
                  !isTopArticle &&
                  domain === (isCrateApp ? 'xing' : 'lebenslauf')
              )
              .map((article) => (
                <ArticleCard
                  key={article.url}
                  {...{
                    title: article.title,
                    description: article.description,
                    to: article.url,
                    image: article.image?.srcWide ?? '',
                    buttonCopy: formatMessage({
                      id: 'CRATE_LEBENSLAUF_STARTPAGE_ARTICLES_READ_MORE',
                    }),
                  }}
                />
              ))}
          </StyledArticles>
        </StyledSection>

        <StyledIllustrationSection>
          <div>
            <StyledAnimationStep $isActive={currentAnimationIndex === 0}>
              <Headline size="xxlarge">
                <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_STEP_1_HEADLINE" />
              </Headline>
              <StyledStepsCopy size="large">
                <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_STEP_1_SUBLINE" />
              </StyledStepsCopy>
            </StyledAnimationStep>

            <StyledAnimationStep $isActive={currentAnimationIndex === 1}>
              <Headline size="xxlarge">
                <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_STEP_2_HEADLINE" />
              </Headline>
              <StyledStepsCopy size="large">
                <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_STEP_2_SUBLINE" />
              </StyledStepsCopy>
            </StyledAnimationStep>

            <StyledAnimationStep $isActive={currentAnimationIndex === 2}>
              <Headline size="xxlarge">
                <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_STEP_3_HEADLINE" />
              </Headline>
              <StyledStepsCopy size="large">
                <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_STEP_3_SUBLINE" />
              </StyledStepsCopy>
            </StyledAnimationStep>

            <StyledAnimationStep $isActive={currentAnimationIndex === 3}>
              <Headline size="xxlarge">
                <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_STEP_4_HEADLINE" />
              </Headline>
              <StyledStepsCopy size="large">
                <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_STEP_4_SUBLINE" />
              </StyledStepsCopy>
            </StyledAnimationStep>
          </div>
          <IntersectionObserver
            onChange={handleIntersectionChange}
            threshold={0.5}
          >
            <StyledAnimationWrapper>
              {animations.map((animation, index) => (
                <StyledAnimation
                  width="470"
                  height="394"
                  aria-hidden="true"
                  src={animation}
                  loading="lazy"
                  $isActive={currentAnimationIndex === index}
                  key={`animation_${index}`}
                />
              ))}
            </StyledAnimationWrapper>
          </IntersectionObserver>
        </StyledIllustrationSection>

        <StyledSection>
          <StyledAppsHeadline size="xxxlarge">
            <FormattedMessage id="CRATE_LEBENSLAUF_STARTPAGE_APPS_HEADLINE" />
          </StyledAppsHeadline>
          <div>
            <a
              href="https://itunes.apple.com/de/app/xing/id297625850"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledAppleBadge
                src={badgesMap[currentLanguage].apple}
                height="60"
                alt="iPhone & iPad"
                loading="lazy"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.xing.android"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledGoogleBadge
                src={badgesMap[currentLanguage].google}
                height="60"
                alt="Android"
                loading="lazy"
              />
            </a>
          </div>
        </StyledSection>
      </Wrapper>
    </>
  );
};
