/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable check-file/filename-naming-convention */
import * as React from 'react';
import type { SVGProps } from 'react';

export type IllustrationSizeVariants =
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge';
export type IllustrationProps = {
  width?: number | string;
  height?: number | string;
  size?: IllustrationSizeVariants;
};
const IllustrationSpotSkills = ({
  size = 'medium',
  width,
  height,
  ...props
}: IllustrationProps) => {
  let presetWidth: string | undefined;
  let presetHeight: string | undefined;
  const presets = {
    small: '48px',
    medium: '68px',
    large: '92px',
    xlarge: '122px',
    xxlarge: '156px',
    xxxlarge: '194px',
    xxxxlarge: '236px',
  };
  if (size) {
    presetWidth = presets[size];
    presetHeight = presets[size];
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 68 68"
      data-xds="IllustrationSpotSkills"
      height={height || presetHeight}
      role="presentation"
      width={width || presetWidth}
      {...props}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M9.722 44.567 23.267 58.14l35.21-36.968 3.648-15.497a.55.55 0 0 0-.658-.658L45.969 8.666z"
        clipRule="evenodd"
      />
      <path
        fill="#B7E854"
        fillRule="evenodd"
        d="m23.334 58.997-2.538 2.538c-4 3.999-10.483 3.999-14.482 0-4-4-4-10.483 0-14.482l3.142-3.143c1.617 3.143 15.205 13.76 13.878 15.087m38.63-46.15-6.63-6.937 7.618-1.893z"
        clipRule="evenodd"
      />
      <path
        fill="#27DBA8"
        fillRule="evenodd"
        d="M18.461 53.027 54.688 16.8l-3.64-3.64-36.227 36.227z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m29.488 17.817 33.26 34.87a3.42 3.42 0 0 1 .08 4.628l-.14.15a3.42 3.42 0 0 1-4.777.06l-34.87-33.26a4.56 4.56 0 1 1 6.447-6.448M21.258 6.67c4.28 3.335 3.845 8.832 1.39 11.366-2.054 2.122-7.71 4.381-12.027-1.474-1.084-1.469-1.17-3.165-1.257-4.844l-.03-.559c-.11-1.858-.356-3.661-2.108-5.076 2.047-.817 8.812-3.484 14.033.587"
        clipRule="evenodd"
      />
      <path
        fill="#27DBA8"
        fillRule="evenodd"
        d="m29.755 30.192-3.353-3.353 5.724-5.723 3.353 3.352z"
        clipRule="evenodd"
      />
      <path
        fill="#B7E854"
        fillRule="evenodd"
        d="M10.195 15.675c1.986.674 1.207-3.312 3.033-2.442 2.454 1.167 2.84.817 3.475.106 1.472-1.649-1.755-4.132-.585-4.876s3.244 1.804 5.353-1.583c-4.521-2.074-5.318-3.723-13.83-.744 1.863 1.542 2.377 8.652 2.554 9.54"
        clipRule="evenodd"
      />
      <path
        fill="var(--xdlColorIllustrationOutlinePrimary)"
        fillRule="evenodd"
        d="M21.873 5.88c3.169 2.47 4.219 6.38 3.324 9.607a5.56 5.56 0 0 1 4.829 1.454l.186.187 2.959 3.103 12.932-12.93L62.17 3.63c1.081-.264 2.061.664 1.898 1.712l-.025.127-3.491 16.272-13.278 13.277L63.47 51.998a4.426 4.426 0 0 1 .233 5.827l-.15.176-.16.17a4.42 4.42 0 0 1-6.002.232l-.172-.155-16.98-16.196-17.552 17.553L8.244 45.16l17.209-17.211-3.103-2.96a5.55 5.55 0 0 1-1.72-4.348c-3.405 1.497-7.728.7-10.815-3.486-.953-1.291-1.272-2.547-1.411-4.688l-.02-.345-.036-.647-.036-.548c-.161-2.086-.507-3.034-1.566-3.944l-.15-.123-1.328-1.074 1.586-.633c2.164-.864 3.906-1.387 6.098-1.606 3.322-.332 6.366.34 8.92 2.333m1.792 12.562a3.56 3.56 0 0 0-.08 4.954l.145.146L58.6 56.8c.907.864 2.316.886 3.232.097l.127-.119.12-.127c.79-.89.81-2.22.068-3.133l-.124-.14-33.26-34.872a3.56 3.56 0 0 0-5.098-.064m2.589 11.534L11.072 45.159l11.616 11.616L37.87 41.592zM46.607 9.624 34.552 21.678l11.34 11.891 12.33-12.329zM13.15 5.537c-1.308.13-2.45.387-3.66.78l-.458.153-.014.005.064.083c.799 1.093 1.096 2.39 1.239 4.434l.022.347.055.997c.116 1.78.358 2.727 1.026 3.632 3.2 4.338 7.832 4.132 10.505 1.37 2.397-2.474 2.128-7.22-1.287-9.88-2.108-1.645-4.645-2.206-7.492-1.92m48.791.195L48.594 8.783l10.45 10.451z"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default IllustrationSpotSkills;
